<template>
  <div class="home container">
    <div class="main-info">
      <img alt="DePigz logo" src="../assets/logo.svg" class="main-info__logo" />
      <div class="main-info__block">
        <h1 class="main-info__title">dePigz</h1>
        <h2 class="main-info__text">
          <span class="main-info__text-line">Friend Tech automated bot</span>
          <span>Every ETH earned is reinvested to purchase dePigz keys</span>
        </h2>
      </div>
    </div>
    <Footer :price="price" />
    <ShoppingTicker :list="latest" />
    <ShoppingList :list="best" />
    <a
      href="https://www.dune.com/depigz/total"
      target="_blank"
      class="footer-more-block"
    >
      <span class="footer-more">View Statistics</span>
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import infra from "@/infra";
import { LatestPurchases } from "@/infra/models/latest-purchases/entities";
import Footer from "@/components/footer.vue";
import ShoppingList from "@/components/shopping-list.vue";
import ShoppingTicker from "@/components/shopping-ticker.vue";

export default defineComponent({
  name: "HomeView",
  components: { ShoppingTicker, ShoppingList, Footer },
  setup() {
    const latest = ref<LatestPurchases[]>([]);
    const best = ref<LatestPurchases[]>([]);
    const price = ref<string>("0");

    const web3 = infra.common.web3.init();

    infra.common.web3.getPrice(web3).then((response) => {
      if (response) {
        try {
          price.value = Number(response).toFixed(3);
        } catch {
          price.value = response;
        }
      }
    });

    infra.wallet.api.getLatestPurchases().then((purchases) => {
      if (purchases && purchases.length) {
        infra.wallet.service.getFreshPurchases(purchases).then((response) => {
          if (response) {
            latest.value = response;
          }
        });
        infra.wallet.service.getBestBuys(web3, purchases).then((response) => {
          if (response) {
            best.value = response;
          }
        });
      }
    });

    // infra.wallet.service.getFreshPurchases([]).then((response) => {
    //   if (response) {
    //     console.log("response", response);
    //     latest.value = response.value;
    //   }
    // });

    return {
      latest,
      best,
      price,
    };
  },
});
</script>

<style lang="scss" scoped>
.main-info {
  display: flex;
  flex-direction: column;
}
.main-info__logo {
  height: 160px;
}
.main-info__text-line {
  display: block;
}
.main-info__block {
  text-align: center;
}
.main-info__title {
  margin-top: 0;
  margin-bottom: 18px;
}
.main-info__text {
  margin-top: 18px;
}
.footer-more-block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 25px;
  text-decoration: none;
}
.footer-more {
  position: relative;
  text-decoration: none;
  border-bottom: 2px solid #000;
  color: #000;
  font-size: 1.5rem;
  transition: 0.3s ease;
}
.footer-more:hover {
  border-color: transparent;
}

@media screen and (min-width: 560px) {
}
@media screen and (min-width: 768px) {
  .main-info {
    flex-direction: row;
  }
  .main-info__block {
    text-align: left;
  }
  .main-info__logo {
    max-width: 30%;
    width: 30%;
    min-width: 30%;
    height: auto;
  }
  .main-info__title {
    margin-top: 42px;
  }
}
</style>
