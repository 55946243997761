import { WalletWeb3 } from "@/infra/models/wallet/entities";
import { ethers } from "ethers";
import {
  ContractCallContext,
  ContractCallResults,
  Multicall,
} from "ethereum-multicall";
import infraConfig from "@/infra/config";
import friendTechAbi from "@/infra/abi/friend-tech";
import { CallContext } from "ethereum-multicall/dist/esm/models";
import formatter from "@/utils/formatter";

const walletWeb3: WalletWeb3 = {
  async getPricesFromListOfWalletAddresses(
    web3: ethers.providers.JsonRpcProvider,
    listOfWalletAddresses: string[]
  ): Promise<{ walletAddress: string; price: number }[]> {
    let wallets: { walletAddress: string; price: number }[] = [];

    try {
      const multiCall = new Multicall({
        ethersProvider: web3,
        tryAggregate: true,
      });
      const contractReference = "friendTechContract";
      const callReference = "getPriceCall_";
      const contractCallContext: ContractCallContext = {
        reference: contractReference,
        contractAddress: infraConfig.web3.contractAddresses.friendTech,
        abi: friendTechAbi,
        calls: listOfWalletAddresses.map((walletAddress) => {
          const call: CallContext = {
            reference: callReference + walletAddress,
            methodName: "getBuyPriceAfterFee",
            methodParameters: [walletAddress, 1],
          };

          return call;
        }),
      };
      const result: ContractCallResults = await multiCall.call(
        contractCallContext
      );

      if (
        result &&
        result.results &&
        contractReference in result.results &&
        result.results[contractReference] &&
        result.results[contractReference].callsReturnContext?.length
      ) {
        result.results[contractReference].callsReturnContext.forEach(
          (callResult) => {
            if (callResult.returnValues?.length && callResult.returnValues[0]) {
              const arrReference = callResult.reference.split("_");
              const walletAddress =
                arrReference.length > 1 ? arrReference[1] : "";
              const price = ethers.utils.formatEther(
                callResult.returnValues[0]
              );
              const priceByNum = formatter.getNum(price);

              if (walletAddress && priceByNum) {
                wallets.push({ walletAddress, price: priceByNum });
              }
            }
          }
        );
      }
    } catch {
      wallets = [];
    }

    return wallets;
  },
};

export default walletWeb3;
