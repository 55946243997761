import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a93cb78"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "purchase__info" }
const _hoisted_4 = {
  key: 0,
  class: "purchase__title ellipsis"
}
const _hoisted_5 = { class: "purchase__text" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "purchase__date"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.item && _ctx.item.walletAddress)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: 'https://www.friend.tech/' + _ctx.item.walletAddress,
        target: "_blank",
        class: "purchase"
      }, [
        (_ctx.item.twitter && _ctx.item.twitter.avatarUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.item.twitter.avatarUrl,
              alt: "Avatar",
              class: "purchase__img"
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.item.twitter && _ctx.item.twitter.nickname)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(_ctx.item.twitter.nickname), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.item.shareAmount)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.item.shareAmount) + " keys ", 1))
              : _createCommentVNode("", true),
            (_ctx.item.date)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.item.date), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}