import { InfraModel } from "@/infra/models/infra/entities";
import { walletController } from "@/infra/wallet/wallet.controller";
import infraConfig from "@/infra/config";
import commonController from "@/infra/common/common.controller";

const infra: InfraModel = {
  config: infraConfig,
  common: commonController,
  wallet: walletController,
};

export default infra;
