import { DateTime } from "luxon";

const formatter = {
  getNum(value: string): number {
    try {
      const number = Number(value);

      if (!Number.isNaN(number) && Number.isFinite(number)) {
        return number;
      } else {
        return 0;
      }
    } catch {
      return 0;
    }
  },
  getIntegerNum(value: string): number {
    const num = formatter.getNum(value);

    return num && Number.isInteger(num) ? num : 0;
  },
  getTimestamp(value: string): number | null {
    const num = formatter.getIntegerNum(value);

    if (num) {
      return num * 1000;
    } else {
      return null;
    }
  },
  getDateAgo(timestamp?: number | null): string {
    if (timestamp) {
      try {
        const startDate = DateTime.fromMillis(timestamp);
        const diff = DateTime.now().diff(startDate);
        const relative = DateTime.now().minus(diff).toRelative({ round: true });

        return relative ? relative : "";
      } catch {
        return "";
      }
    } else {
      return "";
    }
  },
};

export default formatter;
