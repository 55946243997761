import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fda40e3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ticker" }
const _hoisted_2 = {
  key: 0,
  class: "ticker__line"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PurchaseItem = _resolveComponent("PurchaseItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.list && _ctx.list.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
            return (_openBlock(), _createBlock(_component_PurchaseItem, {
              key: 'ticker_' + index + '_' + item.walletAddress,
              item: item
            }, null, 8, ["item"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}