<template>
  <div v-if="list && list.length" class="list">
    <PurchaseDetail
      v-for="(item, index) in list"
      :key="'detail_' + index + '_' + item.walletAddress"
      :item="item"
      class="list__item"
    />
  </div>
  <Spinner v-else class="spinner-block full-width" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { LatestPurchases } from "@/infra/models/latest-purchases/entities";
import PurchaseDetail from "@/components/purchase-detail.vue";
import Spinner from "@/components/spinner.vue";

export default defineComponent({
  name: "ShoppingList",
  components: { Spinner, PurchaseDetail },
  props: {
    list: {
      type: Array as PropType<LatestPurchases[]>,
      default: () => [],
    },
  },
});
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-flow: row wrap;
}
.spinner-block {
  margin: 15px 0;
}
.list__item {
  width: 100%;
  margin: 0.5%;
}

@media screen and (min-width: 560px) {
  .list__item {
    width: 49%;
  }
}
@media screen and (min-width: 768px) {
  .list__item {
    width: 32.3%;
  }
}
@media screen and (min-width: 1024px) {
  .list__item {
    width: 24%;
  }
}
</style>
