import { WalletApi } from "@/infra/models/wallet/entities";
import {
  getLatestPurchasesFromGraph,
  LatestPurchasesFromGraph,
} from "@/infra/models/latest-purchases/entities";
import axios from "axios";
import config from "@/infra/config";
import { TwitterAccount } from "@/infra/models/twitter/entities";

const walletApi: WalletApi = {
  async getLatestPurchases(): Promise<LatestPurchasesFromGraph[]> {
    try {
      const recentPurchases = config.wallet.recentPurchases;
      const { data } = await axios.post<getLatestPurchasesFromGraph>(
        recentPurchases.url,
        {
          query: `
        query MyQuery {
          trades(
            where: { isBuy: true, trader: "${recentPurchases.traderWalletAddress}" }
            orderBy: blockNumber
            orderDirection: desc
          ) {
            subject
            shareAmount
            transactionHash
            blockTimestamp
            }
          }
      `,
        }
      );

      return data && data.data && data.data.trades ? data.data.trades : [];
    } catch {
      return [];
    }
  },
  async getTwitterAccount(
    walletAddress: string
  ): Promise<TwitterAccount | null> {
    try {
      const recentPurchases = config.wallet.recentPurchases;
      const { data } = await axios.get(
        recentPurchases.twitter.url + walletAddress
      );

      return data ? data : null;
    } catch {
      return null;
    }
  },
};

export default walletApi;
