<template>
  <footer class="footer">
    <a
      href="https://www.friend.tech/rooms/0xdf68d1ad12f692fbbac6435afdc5bd9c94a18f28"
      target="_blank"
      class="footer-btn footer-btn_animate"
    >
      <span class="footer-btn__text">Buy</span>
      <span v-if="price && price !== '0'" class="footer-btn__text">{{
        price
      }}</span>
      <Spinner v-else class="spinner-btn" />
      <span class="footer-btn__text">ETH</span>
    </a>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Spinner from "@/components/spinner.vue";

export default defineComponent({
  name: "FooterComponent",
  components: { Spinner },
  props: {
    price: String,
  },
});
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  margin: 0 0 24px;
}
.footer-btn {
  display: flex;
  align-items: center;
  padding: 16px 40px;
  background-color: #000;
  color: #fff;
  font-size: 2rem;
  border-radius: 34px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}
.footer-btn:hover {
  background-color: #333;
}
.footer-btn__text {
  padding: 0 3px;
}
.footer-btn_animate {
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
  animation-name: live;
  animation-duration: 1s;
}
.spinner-btn {
  width: 32px;
  height: 32px;
  margin: 0 3px;
}
</style>
