import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f4290db6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PurchaseDetail = _resolveComponent("PurchaseDetail")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_ctx.list && _ctx.list.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
          return (_openBlock(), _createBlock(_component_PurchaseDetail, {
            key: 'detail_' + index + '_' + item.walletAddress,
            item: item,
            class: "list__item"
          }, null, 8, ["item"]))
        }), 128))
      ]))
    : (_openBlock(), _createBlock(_component_Spinner, {
        key: 1,
        class: "spinner-block full-width"
      }))
}