import { ethers } from "ethers";
import infraConfig from "@/infra/config";
import friendTechAbi, { ContractForFriendTech } from "@/infra/abi/friend-tech";
import { CommonWeb3 } from "@/infra/models/common/entities";

const commonWeb3: CommonWeb3 = {
  init(): ethers.providers.JsonRpcProvider {
    return new ethers.providers.JsonRpcProvider(
      infraConfig.web3.baseRpcAddress
    );
  },
  async getPrice(web3: ethers.providers.JsonRpcProvider): Promise<string> {
    try {
      const friendTechContract = new ethers.Contract(
        infraConfig.web3.contractAddresses.friendTech,
        friendTechAbi,
        web3
      ) as ContractForFriendTech;

      const buyPriceWei = await friendTechContract.getBuyPriceAfterFee(
        infraConfig.web3.contractAddresses.dePigz,
        1
      );

      return ethers.utils.formatEther(buyPriceWei);
    } catch {
      return "0";
    }
  },
};

export default commonWeb3;
