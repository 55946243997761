import { WalletService } from "@/infra/models/wallet/entities";
import {
  LatestPurchases,
  LatestPurchasesFromGraph,
} from "@/infra/models/latest-purchases/entities";
import walletApi from "@/infra/wallet/wallet.api";
import formatter from "@/utils/formatter";
import infraConfig from "@/infra/config";
import walletWeb3 from "@/infra/wallet/wallet.web3";
import { ethers } from "ethers";
import { TwitterAccount } from "@/infra/models/twitter/entities";

const walletService: WalletService = {
  async getFreshPurchases(
    purchases: LatestPurchasesFromGraph[],
    range = infraConfig.wallet.recentPurchases.range.fresh
  ): Promise<LatestPurchases[]> {
    const freshPurchases: LatestPurchases[] = [];

    if (purchases && purchases.length) {
      const freshPurchasesFromGraph = purchases.slice(range.start, range.end);
      const twitterAccounts: Promise<TwitterAccount | null>[] =
        freshPurchasesFromGraph.map((item) => {
          return walletApi.getTwitterAccount(item.subject);
        });

      return Promise.all(twitterAccounts)
        .then((values) => {
          values.forEach((twitterAccount) => {
            if (twitterAccount) {
              const currentPurchase = freshPurchasesFromGraph.find(
                (item) => item.subject === twitterAccount.address
              );

              if (currentPurchase) {
                const timestamp = formatter.getTimestamp(
                  currentPurchase.blockTimestamp
                );

                freshPurchases.push({
                  twitter: {
                    avatarUrl: twitterAccount.twitterPfpUrl,
                    name: twitterAccount.twitterName,
                    nickname: twitterAccount.twitterUsername,
                    link:
                      "https://twitter.com/" + twitterAccount.twitterUsername,
                  },
                  walletAddress: currentPurchase.subject,
                  friendTechLink:
                    "https://www.friend.tech/" + currentPurchase.subject,
                  shareAmount: currentPurchase.shareAmount,
                  date: formatter.getDateAgo(timestamp),
                  timestamp: timestamp || 0,
                });
              }
            }
          });

          return freshPurchases.sort((a, b) => b.timestamp - a.timestamp);
        })
        .catch(() => freshPurchases);
    } else {
      return freshPurchases;
    }
  },
  async getBestBuys(
    web3: ethers.providers.JsonRpcProvider,
    purchases: LatestPurchasesFromGraph[],
    range = infraConfig.wallet.recentPurchases.range.best
  ): Promise<LatestPurchases[]> {
    const bestBuys: LatestPurchases[] = [];

    if (purchases && purchases.length) {
      const bestBuysFromGraph = purchases.slice(range.start, range.end);
      const walletsAtPrices = walletWeb3.getPricesFromListOfWalletAddresses(
        web3,
        bestBuysFromGraph.map((item) => item.subject)
      );
      const twitterAccounts: Promise<TwitterAccount | null>[] =
        bestBuysFromGraph.map((item) => {
          return walletApi.getTwitterAccount(item.subject);
        });

      return Promise.all([walletsAtPrices, ...twitterAccounts])
        .then((values) => {
          const walletsAtPrices = values[0];

          walletsAtPrices
            .sort((a, b) => b.price - a.price)
            .forEach((wallet) => {
              const currentPurchase = bestBuysFromGraph.find(
                (purchase) => purchase.subject === wallet.walletAddress
              );
              const currentTwitterAccount = values.find(
                (twitter) =>
                  twitter &&
                  !Array.isArray(twitter) &&
                  twitter.address === wallet.walletAddress
              ) as TwitterAccount | undefined;

              if (currentTwitterAccount && currentPurchase) {
                const timestamp = formatter.getTimestamp(
                  currentPurchase.blockTimestamp
                );

                bestBuys.push({
                  twitter: {
                    avatarUrl: currentTwitterAccount.twitterPfpUrl,
                    name: currentTwitterAccount.twitterName,
                    nickname: currentTwitterAccount.twitterUsername,
                    link:
                      "https://twitter.com/" +
                      currentTwitterAccount.twitterUsername,
                  },
                  walletAddress: currentPurchase.subject,
                  friendTechLink:
                    "https://www.friend.tech/" + currentPurchase.subject,
                  shareAmount: currentPurchase.shareAmount,
                  date: formatter.getDateAgo(timestamp),
                  timestamp: timestamp || 0,
                  price: wallet.price,
                });
              }
            });

          return bestBuys;
        })
        .catch(() => bestBuys);
    } else {
      return bestBuys;
    }
  },
};

export default walletService;
