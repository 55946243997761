<template>
  <a
    v-if="item && item.walletAddress"
    :href="'https://www.friend.tech/' + item.walletAddress"
    target="_blank"
    class="purchase"
  >
    <img
      v-if="item.twitter && item.twitter.avatarUrl"
      :src="item.twitter.avatarUrl"
      alt="Avatar"
      class="purchase__img"
    />
    <div class="purchase__info">
      <div
        v-if="item.twitter && item.twitter.nickname"
        class="purchase__title ellipsis"
      >
        <span>{{ item.twitter.nickname }}</span>
      </div>
      <div class="purchase__text">
        <span v-if="item.shareAmount"> {{ item.shareAmount }} keys </span>
        <span v-if="item.date" class="purchase__date">
          {{ item.date }}
        </span>
      </div>
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { LatestPurchases } from "@/infra/models/latest-purchases/entities";

export default defineComponent({
  name: "PurchaseItem",
  props: {
    item: {
      type: Object as PropType<LatestPurchases>,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/css/vars.scss";

.purchase {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $text-dark;
  min-width: max-content;
  background-color: #ffd2da;
  border-radius: 28px;
  padding: 4px 16px 4px 4px;
  margin: 7px;
  box-sizing: border-box;
  transition: background-color 0.3s linear;
}
.purchase:hover {
  background-color: #ffe6eb;
}
.purchase:hover .purchase__img {
  transform: scale(1.2);
}
.purchase:last-child {
  margin-right: 0;
}
.purchase__img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}
.purchase__info {
  margin-left: 12px;
}
.purchase__title {
  margin-bottom: 1px;
}
.purchase__text {
  font-size: 14px;
}
.purchase__date {
  font-style: italic;
  color: #666;
  margin-left: 4px;
}
</style>
