import { WalletController } from "@/infra/models/wallet/entities";
import walletApi from "@/infra/wallet/wallet.api";
import walletService from "@/infra/wallet/wallet.service";
import walletWeb3 from "@/infra/wallet/wallet.web3";

export const walletController: WalletController = {
  api: walletApi,
  service: walletService,
  web3: walletWeb3,
};
