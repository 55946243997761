import { InfraConfig } from "@/infra/models/infra/entities";

const infraConfig: InfraConfig = {
  web3: {
    contractAddresses: {
      friendTech: "0xcf205808ed36593aa40a44f10c7f7c2f67d4a4d4",
      dePigz: "0xdf68d1ad12f692fbbac6435afdc5bd9c94a18f28",
    },
    baseRpcAddress: "https://base.llamarpc.com",
  },
  wallet: {
    recentPurchases: {
      url: "https://api.thegraph.com/subgraphs/name/baseperp/friendtech",
      traderWalletAddress: "0x4b2bbb1c14881facd002412a2c68ab24b4b9c8f7",
      allowedCells: ["subject", "shareAmount", "blockTimestamp"],
      nameOfAllowedCells: {
        subject: "Subject",
        shareAmount: "Shares sold",
        blockTimestamp: "Date",
      },
      twitter: {
        url: "https://prod-api.kosetto.com/users/",
      },
      range: {
        fresh: {
          start: 0,
          end: 20,
        },
        best: {
          start: 20,
          end: 40,
        },
      },
    },
  },
};

export default infraConfig;
