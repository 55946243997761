<template>
  <div v-if="item && item.twitter" class="purchase-detail">
    <img
      v-if="item.twitter.avatarUrl"
      :src="item.twitter.avatarUrl"
      alt="Avatar"
      class="purchase-detail__img"
    />
    <div class="purchase-detail__info">
      <div class="purchase-detail__body">
        <div v-if="item.twitter.name" class="purchase-detail__title">
          {{ item.twitter.name }}
        </div>
        <div v-if="item.twitter.nickname" class="purchase-detail__subtitle">
          @{{ item.twitter.nickname }}
        </div>
        <div v-if="item.shareAmount" class="purchase-field">
          <span class="purchase-field__name">Amount:</span>
          <span class="purchase-field__value">{{ item.shareAmount }} keys</span>
        </div>
        <div v-if="item.date" class="purchase-field">
          <span class="purchase-field__name">Date:</span>
          <span class="purchase-field__value">{{ item.date }}</span>
        </div>
        <div v-if="item.price" class="purchase-field">
          <span class="purchase-field__name">Price:</span>
          <span class="purchase-field__value">{{ item.price }}</span>
        </div>
      </div>
      <div class="purchase-detail__footer">
        <a
          v-if="item.twitter.link"
          :href="item.twitter.link"
          target="_blank"
          class="purchase-detail__footer-link"
        >
          <span class="purchase-detail__footer-text">Twitter</span>
        </a>
        <a
          v-if="item.walletAddress"
          :href="'https://www.friend.tech/' + item.walletAddress"
          target="_blank"
          class="purchase-detail__footer-link"
        >
          <span class="purchase-detail__footer-text">Friend.tech</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { LatestPurchases } from "@/infra/models/latest-purchases/entities";

export default defineComponent({
  name: "PurchaseDetail",
  props: {
    item: {
      type: Object as PropType<LatestPurchases>,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/css/vars.scss";

.purchase-detail {
  position: relative;
  color: $text-dark;
  background-color: #ffd2da;
  border-radius: 16px;
  transition: background-color 0.3s linear;
  overflow: hidden;
}
.purchase-detail__img {
  position: absolute;
  right: 0;
  top: 0;
  height: 50%;
  object-fit: contain;
  border-radius: 50%;
  border-top-right-radius: 0;
}
.purchase-detail__info {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px 0 0;
  background-color: rgb(255, 210, 218, 0.8);
  box-sizing: border-box;
}
.purchase-detail__body {
  padding: 0 16px;
}
.purchase-detail__title {
  font-size: 18px;
  font-weight: bold;
}
.purchase-detail__subtitle {
  color: #666;
  font-size: 14px;
}
.purchase-field {
  display: flex;
  margin: 6px 0;
  overflow: hidden;
}
.purchase-field__name {
  color: #666;
  min-width: max-content;
}
.purchase-field__value {
  margin-left: 4px;
}
.purchase-detail__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.purchase-detail__footer-link {
  width: 50%;
  color: $text-dark;
  padding: 16px;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
}
.purchase-detail__footer-text {
  border-bottom: 1px solid #000;
}

@media screen and (min-width: 768px) {
  .purchase-detail__footer {
    display: block;
    margin-top: 14px;
    padding: 0 16px 16px;
  }
  .purchase-detail__footer-link {
    margin-left: 12px;
    padding: 0;
    border-bottom: 1px solid #000;
    transition: 0.3s linear;
  }
  .purchase-detail__footer-link:first-child {
    margin-left: 0;
  }
  .purchase-detail__footer-link:hover {
    border-color: transparent;
  }
  .purchase-detail__footer-text {
    border-bottom: none;
  }
}
</style>
