<template>
  <div class="ticker">
    <div v-if="list && list.length" class="ticker__line">
      <PurchaseItem
        v-for="(item, index) in list"
        :key="'ticker_' + index + '_' + item.walletAddress"
        :item="item"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { LatestPurchases } from "@/infra/models/latest-purchases/entities";
import PurchaseItem from "@/components/purchase-item.vue";

export default defineComponent({
  name: "ShoppingTicker",
  components: { PurchaseItem },
  props: {
    list: {
      type: Array as PropType<LatestPurchases[]>,
      default: () => [],
    },
  },
});
</script>

<style lang="scss" scoped>
.ticker {
  position: relative;
  overflow: hidden;
  min-height: 62px;
  display: flex;
}
.ticker::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 20px;
  right: 0;
  bottom: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(245, 168, 182, 1) 100%
  );
  z-index: 1;
}
.ticker::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 20px;
  left: 0;
  bottom: 0;
  background: rgb(245, 168, 182);
  background: linear-gradient(
    90deg,
    rgba(245, 168, 182, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
}
.ticker__line {
  display: flex;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ticker;
  animation-duration: 35s;
  padding-left: 90%;
}
</style>
